import YoutubeEmbed from "../../../../content/pages/songtales/youtube-embed";
import "../../../../content/pages/songtales/style.css";
import { Container, Row, Col } from "react-bootstrap";
import * as React from 'react';
export default {
  YoutubeEmbed,
  Container,
  Row,
  Col,
  React
};