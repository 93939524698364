import baseTheme from "@lekoarts/gatsby-theme-jodie/src/gatsby-plugin-theme-ui";

const contentStyles = {
    px: [3, 3, 4, 5],
    py: [3, 3, 4, 5],
    maxWidth: `6xl`,
    margin: `0 auto`,
}

export default {
    ...baseTheme,
    colors: {
        ...baseTheme.colors,
        primary: `#b75e09`,
        primaryLight: `#f18a27`,
        textMuted: `#46505f`,
        textMutedLight: `#a0aab8`,
    },
    sidebar: {
        normal: `200px`,
        wide: `300px`,
    },
    content: {
        page: {
            ...contentStyles,
        },
        project: {
            ...contentStyles,
        },
        imageList: {
            ...contentStyles,
            ".gatsby-image-wrapper:not(:last-child)": {
                marginBottom: 5,
            },
        },
    },
    text: {
        heading: {
            fontFamily: `heading`,
            fontWeight: 400,
            lineHeight: `heading`,
            color: `heading`,
            letterSpacing: `0em`,
        },
    },
    buttons: {
        ...baseTheme.buttons,
        close: {
            cursor: 'pointer',
            borderRadius: 0,
            backgroundColor: 'background',
            width: 24,
            height: 24,
        },
    },
    styles: {
        ...baseTheme.styles,
        h1: {
            variant: `text.heading`,
            fontSize: [5, 5, 5, 6],
            mt: 2,
        },
        h2: {
            variant: `text.heading`,
            fontSize: [4, 4, 4, 5],
            mt: 2,
        },
        h3: {
            variant: `text.heading`,
            fontSize: [3, 3, 3, 4],
            mt: 3,
        },
        h4: {
            variant: `text.heading`,
            fontSize: [2, 2, 2, 3],
        },
        h5: {
            variant: `text.heading`,
            fontSize: [1, 1, 1, 2],
        },
    }
};
